<template>
  <div class="mb-5">
    <div class="d-flex justify-space-between align-center" :style="{ position: 'relative' }">
      <div class="d-flex">
        <v-icon v-if="icon" left size="30" color="primary">mdi-{{ icon }}</v-icon>
        <back-square v-if="back" :url="back" />

        <div>
          <h1 
            class="text-h5 font-weight-bold text-uppercase"
            :contenteditable="editable"
            @blur="updated($event)"
          >
            {{ title }}
          </h1>
          <v-sheet v-if="$slots.description" max-width="600" class="grey--text mt-1">
            <slot name="description"></slot>
          </v-sheet>
        </div>
      </div>
      <slot name="center"></slot>
      <slot name="actions"></slot>
    </div>

    <v-divider class="my-5"></v-divider>
  </div>

</template>

<script>

export default {
  name: 'AdminTitle',
  props: {
    back: String,
    icon: String,
    title: String,
    editable: Boolean,
  },

  methods: {
    updated(e) {
      if (e.target.innerHTML.trim() !== this.title) {
        this.$emit('updated', e.target.innerHTML.trim())
      }
    }
  }
}

</script>
